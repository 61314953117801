import React from 'react'
import { BmwServices } from './BmwServices'
import { DefaultServices } from './DefaultServices'

const CustomServicesMap = {
  'bmw-mental-health': <BmwServices />,
  default: <DefaultServices />,
}

export { CustomServicesMap }
export default CustomServicesMap
