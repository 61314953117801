import { get, post, put } from './request'
import { RequestTypeElement } from '../store/request/request.interface'
import { IncomingRequest } from '../store/incomingRequest/incomingRequest.interface'
import store from '../store'
import mixpanel from 'mixpanel-browser'
import validateLocales from '../helpers/languageFormatter'

export async function fetchRequestTypeList({
  providerTypeId,
  language,
  specialization,
}: {
  providerTypeId: number
  language?: string
  specialization?: string
}) {
  const profileContents = await get<RequestTypeElement[]>(
    `/request/type?lng=${validateLocales(language)}`,
    {
      params: { providerTypeId, specialization },
    }
  )
  return profileContents
}

export async function fetchComplaints({
  language,
  specialization,
}: {
  language?: string
  specialization?: string
}) {
  const complaints = await get(`/request/complaints`, {
    params: { lng: language, specialization },
  })

  return complaints
}

export async function sendRequestFeedback({ body }: any) {
  const { message } = await post<any>('/request/feedback', body)
  return message
}

export async function fetchRequesterInfo() {
  const requesterInfo = await get<any>('/user/requesterInfo')
  return requesterInfo
}

export async function putRequestStatus({ body }: any) {
  const { message } = await put<any>('/user/request/status', body)
  return message
}

export async function updateRequestStatus({
  requestId,
  body,
}: {
  requestId: number | undefined
  body: any
}) {
  if (!requestId) {
    console.warn(
      "The request ID must be specified, status update won't be sent",
      body
    )

    return Promise.reject(null)
  }

  return await put(`/request/${requestId}/status`, body)
}

export async function sendProviderResponse({ body }: any) {
  const { message } = await put<any>('/request/providerResponse', body)
  return message
}

export async function fetchIncomingRequest({
  requestId,
  language,
}: {
  requestId: number
  language: string
}) {
  const profileContents = await get<IncomingRequest>(
    `/request/${requestId}?lng=${language}`
  )
  return profileContents
}

export async function postNewRequest({
  payedAsOneOff,
  language,
  paymentDeviceReferenceTransactionId,
  returnTo,
}: {
  payedAsOneOff: boolean
  language?: string | null
  paymentDeviceReferenceTransactionId?: string | null
  returnTo?: string | null
}) {
  const {
    requestTypeIds,
    requestDescription,
    requestDescriptionImage,
    specialization,
    selectedComplaintIds,
    childAge,
    childName,
    productSku,
    attachments,
  } = store.getState().request

  const { couponCode } = store.getState().coupon

  const { cards } = store.getState().profile

  const referenceTransactionId = payedAsOneOff
    ? ''
    : paymentDeviceReferenceTransactionId || cards[0]?.referenceTransactionId

  const body = {
    requestTypeIds,
    requestDescription,
    img: requestDescriptionImage,
    referenceTransactionId: referenceTransactionId ?? '',
    // This will be undefined if the user haven't entered a couponCode
    // Or entered a couponCode but it's not valid
    couponCode,
    language,
    returnTo,
    selectedComplaintIds,

    // The specialization may require additional input data
    specialization,
    childAge,
    childName,

    // Send the selected product SKU for determining the price
    productSku,
    attachments: attachments?.map((a) => a.id),
  }

  const apiEndpointToCall = payedAsOneOff
    ? '/user/request-one-off'
    : '/user/request'

  const request = await post<{
    message: string
    requestId: number
    url: string
  }>(apiEndpointToCall, body)

  mixpanel.track('Request posted', request)

  return request
}

export async function getUserRequests({
  language = 'hu',
}: {
  language?: string
}) {
  const lng = validateLocales(language)

  console.log('getUserRequests()', { language, validatedLocale: lng })
  const data = await get('/user/request', {
    params: { lng },
  })
  return data
}
