/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RadioGroupAnswer } from './RadioGroupAnswer'
import { CheckboxAnswer } from './CheckboxAnswer'
import { BmiAnswer } from './BmiAnswer'
import { OpenEndedAnswer } from './OpenEndedAnswer'

const AnswerBox = ({ question, onAnswered, disabled }) => {
  if (question && question.type) {
    switch (question.type.toLowerCase()) {
      case 'radio':
      case 'range-1-10':
      case 'range-1-7':
      case 'range-1-6':
      case 'range-0-6':
      case 'range-0-5-times-4':
      case 'range-0-3':
      case 'reverse-range-1-10':
      case 'reverse-range-1-7':
      case 'reverse-range-1-6':
      case 'reverse-range-0-6':
      case 'reverse-range-0-5-times-4':
      case 'reverse-range-0-3':
        return (
          <RadioGroupAnswer
            question={question}
            onAnswered={onAnswered}
            disabled={disabled}
            row={question.type !== 'radio'}
          />
        )

      case 'checkbox':
        return (
          <CheckboxAnswer
            question={question}
            onAnswered={onAnswered}
            disabled={disabled}
          />
        )

      case 'bmi':
        return (
          <BmiAnswer
            question={question}
            onAnswered={onAnswered}
            disabled={disabled}
          />
        )

      case 'open-ended':
        return (
          <OpenEndedAnswer
            question={question}
            onAnswered={onAnswered}
            disabled={disabled}
          />
        )

      default:
        return 'Not implemented for question type: ' + question.type
    }
  }

  return 'Not implemented'
}

AnswerBox.displayName = 'AnswerBox'

AnswerBox.propTypes = {}

AnswerBox.defaultProps = {}

export default AnswerBox
export { AnswerBox }
