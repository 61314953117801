/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core'
import { useStoreState } from '../../store/store.hooks'
import CountryFlag from '../CountryFlag/CountryFlag'
import { get } from '../../services/request'

const supportedLanguages = [
  {
    locales: ['hu', 'hu-hu'],
    targetLocale: 'hu',
    text: 'hu',
    icon: 'hu',
  },
  {
    locales: ['en', 'en-us', 'en-gb'],
    targetLocale: 'en',
    text: 'en',
    icon: 'en',
  },
  {
    locales: ['ua', 'uk-ua', 'ru', 'ru-ru'],
    targetLocale: 'ua',
    text: 'ua',
    icon: 'ua',
  },
]

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}))

const LanguageSelector = ({ onChange }) => {
  const classes = useStyles()

  const { i18n, t } = useTranslation('common', 'languageSelector')
  const { language: browserLanguage } = i18n

  const { profileContents } = useStoreState((store) => store.profile)
  const [anchor, setAnchor] = useState(null)
  const [features, setFeatures] = useState({})
  const [languageUrls, setLanguageUrls] = useState({})

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const serverSideConfig = await get('/config')
        setFeatures(serverSideConfig.features)
        setLanguageUrls(serverSideConfig.languageUrls)
      } catch (err) {
        console.error('Failed to fetch server side config', err)
      }
    }

    fetchConfig()
  }, [])

  const selectedLanguage = supportedLanguages.find((language) =>
    language.locales.some(
      (locale) => locale.toLowerCase() === browserLanguage.toLowerCase()
    )
  )

  if (!selectedLanguage) {
    console.warn('Unsupported language has been specified', browserLanguage)
  }

  const closeMenu = () => {
    setAnchor(null)
  }

  const changeLanguage = (lng) => {
    const oldLng = i18n.language
    // Will not be switching i18n language here, it's always
    // determined by the hostname

    if (window.location.hostname === 'localhost') {
      i18n.changeLanguage(lng.targetLocale)
      // Persist the language selection
      localStorage.setItem('preferredLanguage', lng.targetLocale)
    } else {
      if (oldLng !== lng.targetLocale) {
        onChange(lng, languageUrls[lng.targetLocale])
      }
    }

    closeMenu()
  }

  const handleClick = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    closeMenu()
  }

  if (!features || features.englishVersion === false) {
    return null
  }

  const renderedComponent = (
    <Button
      className={classes.button}
      disableElevation
      variant="text"
      onClick={handleClick}
      startIcon={<CountryFlag countryCode={selectedLanguage.icon} />}
    >
      <Hidden mdDown>{t(selectedLanguage.text)}</Hidden>
    </Button>
  )

  return (
    <>
      {profileContents.userTypeId === 1 ? (
        <Tooltip title={t('tooltipText')}>{renderedComponent}</Tooltip>
      ) : (
        renderedComponent
      )}

      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={handleClose}>
        {supportedLanguages.map((l) => (
          <MenuItem key={l.targetLocale} onClick={() => changeLanguage(l)}>
            <ListItemIcon>
              <CountryFlag countryCode={l.icon} state="listItem" />
            </ListItemIcon>
            <Typography variant="inherit">{t(l.text)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

LanguageSelector.displayName = 'LanguageSelector'

LanguageSelector.propTypes = {}

LanguageSelector.defaultProps = {}

export default LanguageSelector
export { LanguageSelector }
