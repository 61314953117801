/*
    Copyright © DokiApp, 2023
*/

import React, { useEffect, useState } from 'react'
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { Heading } from '../../components/Text'
import { GradientButton } from '../../components/Button'
import { get } from '../../services/request'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'

const useStyles = makeStyles((theme) => ({
  evaluationDescription: {
    textAlign: 'justify',
    '& a': {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: '#1E2864',
    },
  },
  evaluationHeader: {
    fontWeight: 'bold',
    color: '#1E2864',
    marginBottom: '1rem',
  },
  explanationPaper: {
    padding: '1rem',
    height: '100%',
  },
  important: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '1rem 0',
  },
  intro: {
    margin: '0 2rem 2rem',
    textAlign: 'justify',
  },
  introText: {
    fontSize: '1.1rem',
    '& a': {
      textDecoration: 'none',
      color: '#1E2864',
      fontWeight: 600,
    },
  },
  missingEvaluation: {
    fontWeight: 'bold',
    color: '#1E2864',
  },
  page: {
    backgroundColor: '#fff',
    borderRadius: '1rem',
  },
  pageHeader: {
    marginBottom: theme.spacing(3),
  },
  privacyInfoContainer: {
    marginTop: '2rem',
  },
  scheduleContainer: {
    marginTop: '2rem',
  },
  scoreBox: {
    padding: '1rem',
    backgroundColor: 'rgba(30, 40, 100, 0.05)',
    border: '1px solid rgba(30, 40, 100, 0.1)',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 8px 0 rgba(30, 40, 100, 0.4)',
  },
  scorePaper: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
  },
  scoreText: {
    color: '#1E2864',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  surveysContainer: {
    margin: '1rem 2rem',
  },
}))

const CorporatePsychologicalDiagnosticsEvaluation = ({}) => {
  const classes = useStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation('survey')
  const { relativeNavigate } = useRelativeNavigate()
  const [demographics, setDemographics] = useState(null)
  const [surveys, setSurveys] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchDemographics = async () => {
    try {
      setLoading(true)
      const corporateDiagnostics = await get(
        `/surveys/corporate-diagnostics?lng=${language}`
      )

      setDemographics(corporateDiagnostics.demographics)

      const { surveys: allSurveys } = corporateDiagnostics
      const surveysToShow = allSurveys.filter((s) => s.includeInEvaluation)

      setSurveys(surveysToShow)
    } catch (err) {
      console.error('Failed to fetch demographics and/or surveys', err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // Check if we already have demographics saved

    fetchDemographics()
  }, [])

  const handleNavigateToScheduler = () => {
    relativeNavigate('/scheduler')
  }

  return (
    <>
      <Grid container className={classes.page}>
        <NavigateBack />
        <Grid item xs={12} className={classes.pageHeader}>
          <Heading>{t('corporatePsychoDiagResultsHeader')}</Heading>
        </Grid>

        <Grid item xs={12} className={classes.intro}>
          <Typography
            className={classes.introText}
            dangerouslySetInnerHTML={{
              __html: t('corporatePsychoDiagResultsIntro'),
            }}
          />
        </Grid>

        <Grid item xs className={classes.surveysContainer}>
          {surveys.map((survey) => (
            <Grid container spacing={3} key={survey.id}>
              <Grid item xs={4}>
                <Paper className={classes.scorePaper} elevation={3}>
                  <Typography className={classes.scoreText}>
                    {survey.title}
                  </Typography>
                  <Box className={classes.scoreBox}>
                    {t('corporatePsychoDiagResultsScore')}:{' '}
                    {`${survey.userScore || '-'} / ${survey.maxScore}`}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper className={classes.explanationPaper} elevation={3}>
                  {!(survey.evaluation && survey.evaluation.text) && (
                    <Typography className={classes.missingEvaluation}>
                      {t('corporatePsychoDiagMissingEvaluation')}
                    </Typography>
                  )}

                  {survey.evaluation && survey.evaluation.text && (
                    <Typography className={classes.evaluationHeader}>
                      {survey.evaluation.text}
                    </Typography>
                  )}

                  {survey.evaluation && survey.evaluation.description && (
                    <Typography
                      className={classes.evaluationDescription}
                      dangerouslySetInnerHTML={{
                        __html: survey.evaluation.description,
                      }}
                    ></Typography>
                  )}
                </Paper>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid container className={classes.scheduleContainer}>
          <Grid item xs>
            <Typography className={classes.important}>
              {t('corporatePsychoDiagScheduleText')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={3}>
            <GradientButton onClick={handleNavigateToScheduler}>
              {t('corporatePsychoDiagScheduleButton')}
            </GradientButton>
          </Grid>
        </Grid>

        <Grid container className={classes.privacyInfoContainer}>
          <Grid item xs>
            <Typography className={classes.important}>
              {t('corporatePsychoDiagPrivacyInfo')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

CorporatePsychologicalDiagnosticsEvaluation.displayName =
  'CorporatePsychologicalDiagnosticsEvaluation'

export default CorporatePsychologicalDiagnosticsEvaluation
export { CorporatePsychologicalDiagnosticsEvaluation }
