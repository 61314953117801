import { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { CustomServicesMap } from './CustomServicesMap'

export const Services = () => {
  const { account, activeVoucher, subscriptionRetrieved } = useStoreState(
    (state) => state.subscription
  )
  const { fetchSubscription } = useStoreActions((state) => state.subscription)
  const [servicesComponent, setServicesComponent] = useState(null)

  useEffect(() => {
    fetchSubscription()
  }, [])

  useEffect(() => {
    if (subscriptionRetrieved) {
      if (account) {
        if (CustomServicesMap && CustomServicesMap[account.key]) {
          setServicesComponent(CustomServicesMap[account.key])
        } else {
          setServicesComponent(CustomServicesMap.default)
        }
      }
    }
  }, [subscriptionRetrieved, account, activeVoucher])

  return servicesComponent
}
