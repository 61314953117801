import React, { useEffect } from 'react'
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Heading } from '@/components/Text'
import { WhiteButton } from '@/components'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { MYMEDIO_URLS } from '../../constants'
import { normalizeLanguage } from '../../util'
import { navigate } from '@reach/router'

const useStyles = makeStyles((theme) => ({
  blueBubble: {
    background:
      'linear-gradient(90deg, rgb(64, 171, 239) 0%, rgb(83, 179, 240) 100%) rgb(60, 170, 240)',
    color: 'white',
  },

  bubbleBookingHeader: {
    fontSize: '1rem',
  },

  bubbleBookingSubheader: {
    fontSize: '0.8rem',
  },

  bubbleHeader: {
    fontSize: '1.25rem',
    marginTop: '1rem',
    fontWeight: 'bold',
  },

  bubblePaper: {
    borderRadius: '1rem',
    margin: '1rem',
    padding: '1rem',
  },

  bubbleSubheader: {
    fontSize: '1rem',
  },

  bulletPoints: {
    textAlign: 'left',
    fontSize: '1rem',
  },

  firstParagraph: {
    textAlign: 'justify',
    margin: '2rem 1rem 1rem 1rem',
    fontSize: '1rem',
  },

  headerTypography: {
    textTransform: 'uppercase',
  },

  headline: {
    fontSize: '1.5rem',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#454d7f',
    margin: '2rem 0 1rem 0',
  },

  mutedPinkBubble: {
    background: '#B15F8E',
    color: 'white',
  },

  nextButton: {
    marginTop: '1rem',
  },

  pinkBubble: {
    background:
      'linear-gradient(90deg, rgb(235, 46, 103) 0%, rgb(229, 62, 116) 100%) rgb(239, 30, 91)',
    color: 'white',
  },

  root: {},

  secondParagraph: {
    textAlign: 'justify',
    margin: '1rem',
    fontSize: '1rem',
  },

  servicesContainer: {
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
}))

const BmwServices = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation('bmwServices')
  const language = normalizeLanguage(i18n.language)
  const isInternational = language !== 'hu'

  const { fetchSettings } = useStoreActions((actions) => actions.settings)
  const { settingsByKey } = useStoreState((state) => state.settings)

  const navigateInNewTab = (url) => {
    if (!url) return

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const navigateToMyMedioUrl = (mymedioUrl) => {
    const url = settingsByKey[mymedioUrl]

    if (!url) {
      console.error(`Tried to navigate to an unknown MyMedio URL`, mymedioUrl)
    }

    navigateInNewTab(url)
  }

  const navigateToSurvey = () => {
    console.log('navigate to survey')
    navigate('/patient/surveys/wellness-suite')
  }

  const navigateToOnlineConsultation = () => {
    if (isInternational) {
      navigateToMyMedioUrl(MYMEDIO_URLS.THERAPIST_INTL)
    } else {
      navigateToMyMedioUrl(MYMEDIO_URLS.THERAPIST)
    }
  }

  const navigateToOfflineConsultation = () => {
    if (isInternational) {
      navigateToMyMedioUrl(MYMEDIO_URLS.BMW_OFFLINE_THERAPIST_INTL)
    } else {
      navigateToMyMedioUrl(MYMEDIO_URLS.BMW_OFFLINE_THERAPIST)
    }
  }

  const navigateToMentalAcademy = () => {
    alert('Go where?')
  }

  const navigateToBlog = () => {
    navigateInNewTab('https://blog.dokiapp.hu/')
  }

  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  return (
    <>
      <Grid container xs={12} className={classes.servicesContainer}>
        {/* <NavigateBack /> */}
        <Heading extraClasses={[classes.headerTypography]}>
          {t('header')}
        </Heading>

        <Container component="main" className={classes.bodyContainer}>
          <div
            className={classes.firstParagraph}
            dangerouslySetInnerHTML={{
              __html: t('firstParagraph'),
            }}
          ></div>

          <ul className={classes.bulletPoints}>
            <li>{t('bullet1')}</li>
            <li>{t('bullet2')}</li>
            <li>{t('bullet3')}</li>
          </ul>

          <div
            className={classes.secondParagraph}
            dangerouslySetInnerHTML={{
              __html: t('secondParagraph'),
            }}
          ></div>

          <Typography variant="h3" className={classes.headline}>
            {t('howToBook')}
          </Typography>

          <Typography variant="h5" className={classes.bubbleHeader}>
            {t('bubbleHeader1')}
          </Typography>

          <Grid item xs={12}>
            <Paper
              className={[classes.bubblePaper, classes.blueBubble].join(' ')}
            >
              <Typography>{t('bubbleText1')}</Typography>
            </Paper>
          </Grid>

          <Typography variant="h5" className={classes.bubbleHeader}>
            {t('bubbleHeader2')}
          </Typography>

          <Grid item xs={12}>
            <Paper
              className={[classes.bubblePaper, classes.pinkBubble].join(' ')}
            >
              <Typography>{t('bubbleText2')}</Typography>
              <WhiteButton
                className={classes.nextButton}
                onClick={navigateToSurvey}
              >
                {t('nextButton')}
              </WhiteButton>
            </Paper>
          </Grid>

          <Typography variant="h5" className={classes.bubbleHeader}>
            {t('bubbleHeader3')}
          </Typography>

          <Typography variant="h6" className={classes.bubbleSubheader}>
            {t('bubbleSubheader3')}
          </Typography>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Paper
                className={[classes.bubblePaper, classes.pinkBubble].join(' ')}
              >
                <Typography className={classes.bubbleBookingHeader}>
                  {t('bubbleBookingHeader1')}
                </Typography>
                <Typography className={classes.bubbleBookingSubheader}>
                  {t('bubbleBookingSubheader1')}
                </Typography>
                <WhiteButton
                  className={classes.nextButton}
                  onClick={navigateToOnlineConsultation}
                >
                  {t('bookButton')}
                </WhiteButton>
              </Paper>
            </Grid>

            {/* International version: no offline therapy */}
            {!isInternational && (
              <Grid item xs={12} md={6}>
                <Paper
                  className={[classes.bubblePaper, classes.pinkBubble].join(
                    ' '
                  )}
                >
                  <Typography className={classes.bubbleBookingHeader}>
                    {t('bubbleBookingHeader2')}
                  </Typography>
                  <Typography className={classes.bubbleBookingSubheader}>
                    {t('bubbleBookingSubheader2')}
                  </Typography>
                  <WhiteButton
                    className={classes.nextButton}
                    onClick={navigateToOfflineConsultation}
                  >
                    {t('bookButton')}
                  </WhiteButton>
                </Paper>
              </Grid>
            )}
          </Grid>

          <Typography variant="h6" className={classes.bubbleSubheader}>
            {t('bubbleBookingTextAfter1')}
          </Typography>

          <Typography variant="h6" className={classes.bubbleSubheader}>
            {t('bubbleBookingTextAfter2')}
          </Typography>

          <Typography variant="h5" className={classes.bubbleHeader}>
            {t('bubbleHeader4')}
          </Typography>

          <Paper
            className={[classes.bubblePaper, classes.blueBubble].join(' ')}
          >
            <Typography>{t('bubbleText4')}</Typography>
          </Paper>

          {/* International version: no mental academy, no blog */}
          {!isInternational && (
            <>
              <Typography variant="h3" className={classes.headline}>
                {t('selfDevelopment')}
              </Typography>

              <Paper
                className={[classes.bubblePaper, classes.mutedPinkBubble].join(
                  ' '
                )}
              >
                <Typography className={classes.bubbleBookingHeader}>
                  {t('selfDevelopmentHeader1')}
                </Typography>
                <Typography className={classes.bubbleBookingSubheader}>
                  {t('selfDevelopmentSubheader1')}
                </Typography>
                <WhiteButton
                  className={classes.nextButton}
                  onClick={navigateToMentalAcademy}
                >
                  {t('nextButton')}
                </WhiteButton>
              </Paper>

              <Paper
                className={[classes.bubblePaper, classes.mutedPinkBubble].join(
                  ' '
                )}
              >
                <Typography className={classes.bubbleBookingHeader}>
                  {t('selfDevelopmentHeader2')}
                </Typography>
                <Typography className={classes.bubbleBookingSubheader}>
                  {t('selfDevelopmentSubheader2')}
                </Typography>
                <WhiteButton
                  className={classes.nextButton}
                  onClick={navigateToBlog}
                >
                  {t('nextButton')}
                </WhiteButton>
              </Paper>
            </>
          )}
        </Container>
      </Grid>
    </>
  )
}

BmwServices.displayName = 'BmwServices'
export { BmwServices }
export default BmwServices
