import React, { useEffect } from 'react'
import {
  Avatar,
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
//import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 80,
    height: 80,
    boxShadow: '0 5px 10px 0 rgba(100,115,175,0.4)',
    marginRight: '1rem',
  },
  complaintsContainer: {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #C1C7DF',
    textAlign: 'left',
  },
  complaintsList: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  complaintsText: {
    whiteSpace: 'pre-wrap',
    fontSize: '1.25rem',
    marginTop: '0.3rem',
  },
  dateTimeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1rem',
    width: '100%',
  },
  dateTimeTypography: {
    color: '#6473AF',
    fontSize: 12,
    fontWeight: 'bold',
  },
  feedbackContainer: {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  infoContainer: {
    borderRadius: 15,
    border: '1px solid #C1C7DF',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.2)',
  },
  noMeetingsYet: {
    color: '#1E2864',
    fontWeight: 'bolder',
  },
  noMeetingsYetContainer: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //textAlign: 'center',
  },
  patientInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #C1C7DF',
    padding: '1.5rem',
  },
  patientName: {
    margin: 0,
    fontSize: 24,
    fontWeight: 'bold',
  },
  responseContainer: {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    //borderBottom: '1px solid #C1C7DF', // if the last container is this, add the border back (this container is after the feedback container)
    textAlign: 'left',
  },
  requestBox: {
    padding: '1rem',
    width: '100%',
  },
  root: {
    marginTop: '1rem',
    width: '100%',
  },
  sectionHeader: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#6473AF',
  },
}))

const formatDateTime = (startDate) => {
  return dayjs(startDate).format('YYYY.MM.DD. HH:mm')
}

const ProfessionalMeetingList = ({ meetings }) => {
  const classes = useStyles()
  const { t } = useTranslation('professionalMeetings')

  if (!meetings || !Array.isArray(meetings) || meetings.length < 1) {
    return (
      <div className={classes.noMeetingsYetContainer}>
        <Typography variant="h6" className={classes.noMeetingsYet}>
          {t('noMeetingsYet')}
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        {meetings.map((meeting) => (
          <Box key={meeting.requestId} className={classes.requestBox}>
            <div className={classes.dateTimeContainer}>
              <Typography className={classes.dateTimeTypography}>
                {formatDateTime(meeting.createdAt)}
              </Typography>
            </div>
            <div className={classes.infoContainer}>
              <div className={classes.patientInfoContainer}>
                <Avatar
                  alt="user"
                  src={meeting.patientAvatarUrl}
                  className={classes.avatar}
                />
                <div>
                  <h3 className={classes.patientName}>{meeting.patientName}</h3>
                </div>
              </div>
              <div className={classes.complaintsContainer}>
                <span className={classes.sectionHeader}>
                  {t('complaintsHeader')}
                </span>
                <span className={classes.complaintsList}>
                  {meeting.complaints.join(', ')}
                </span>
                <span className={classes.complaintsText}>
                  {meeting.patientComplaint}
                </span>
              </div>
              <div className={classes.responseContainer}>
                <span className={classes.sectionHeader}>
                  {t('responseHeader')}
                </span>
                <span className={classes.complaintsText}>
                  {meeting.professionalResponse}
                </span>
              </div>
              {/* <div className={classes.feedbackContainer}>
                <span className={classes.sectionHeader}>
                  {t('feedbackHeader')}
                </span>

                {meeting.patientSatisfied === null && (
                  <span className={classes.complaintsText}>
                    {t('noFeedback')}
                  </span>
                )}

                {meeting.patientSatisfied === true && (
                  <span className={classes.complaintsText}>
                    {t('patientSatisfied')}
                  </span>
                )}

                {meeting.patientSatisfied === false && (
                  <span className={classes.complaintsText}>
                    {t('patientDissatisfied')}
                  </span>
                )}
              </div> */}
            </div>
          </Box>
        ))}
      </Grid>
    </>
  )
}

ProfessionalMeetingList.displayName = 'ProfessionalMeetingList'
export { ProfessionalMeetingList }
export default ProfessionalMeetingList
