import React, { useEffect } from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { Heading } from '@/components/Text'
import { NavigateBack } from '@/components/Nav/NavigateBack'
import { Webinar } from './Webinar'

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    paddingBottom: '2rem',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  comingUpText: {
    fontSize: '2rem',
    color: '#f01e5a',
    fontWeight: 'bold',

    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  },

  headlineText: {
    textAlign: 'center',
    margin: '1rem 0',
    fontSize: '1rem',
    color: '#454d7f',

    [theme.breakpoints.down('md')]: {
      margin: '1rem 3rem',
    },
  },

  introSubheadingText: {
    margin: '1rem 3rem',
    textAlign: 'justify',
    fontSize: '1rem',
  },

  webinarsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
}))

const Webinars = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const {
    i18n: { language },
    t,
  } = useTranslation('webinars')

  const { fetchWebinars } = useStoreActions((state) => state.webinars)
  const { webinars } = useStoreState((state) => state.webinars)

  useEffect(() => {
    fetchWebinars({ language })
  }, [fetchWebinars])

  return (
    <>
      <Grid container xs={12} className={classes.webinarsContainer}>
        <NavigateBack />
        <Heading>{t('header')}</Heading>

        <Container component="main" className={classes.bodyContainer}>
          <div
            className={classes.introSubheadingText}
            dangerouslySetInnerHTML={{
              __html: t('intro'),
            }}
          ></div>

          <div
            className={classes.headlineText}
            dangerouslySetInnerHTML={{
              __html: t('headline'),
            }}
          ></div>

          <Typography className={classes.comingUpText}>
            {t('comingUp')}
          </Typography>

          <Container>
            {webinars.map((webinar) => (
              <Webinar key={webinar.id} webinar={webinar} />
            ))}
          </Container>
        </Container>
      </Grid>
    </>
  )
}

Webinars.displayName = 'Webinars'
export { Webinars }
export default Webinars
