/*
    Copyright © DokiApp, 2023
*/

import React, { useEffect, useState } from 'react'
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  descriptionContainer: {
    margin: '1rem 2rem',
  },
  descriptionPaper: {
    padding: '1rem',
  },
  descriptionText: {
    textAlign: 'justify',
  },
  legendContainer: {
    marginTop: '1rem',
  },
  legendPaper: {
    padding: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  legendText: {
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  surveyLegend: {
    justifyContent: 'center',
    '& p': {
      color: '#1E2864',
    },
  },
}))

const SurveyLegendRange = ({ from = 1, to = 10, legends = [] }) => {
  const classes = useStyles()
  const { t } = useTranslation('survey')

  return (
    <Grid container justifyContent="center" className={classes.legendContainer}>
      <Paper className={classes.legendPaper} elevation={0}>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Grid container direction="column">
                {_.range(from, to + 1).map((i) => (
                  <Grid key={i} item>
                    <Typography className={classes.legendText}>{i}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="column">
                {legends.map((legend) => (
                  <Grid item key={legend}>
                    <Typography className={classes.legendText}>
                      {legend}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid container spacing={5}>
            {_.range(from, to + 1).map((i) => (
              <Grid key={i} item xs>
                <Typography className={classes.legendText}>{i}</Typography>
              </Grid>
            ))}
          </Grid>
          {legends && legends.length > 0 && (
            <Grid container spacing={5}>
              {legends.map((legend) => (
                <Grid item xs key={legend}>
                  <Typography className={classes.legendText}>
                    {legend}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Paper>
    </Grid>
  )
}

const SurveyLegend = ({ survey }) => {
  const classes = useStyles()
  const { t } = useTranslation('survey')

  if (!survey || !survey.legendType) {
    return null
  }

  let legendComponent = null
  switch (survey.legendType) {
    case 'stress':
      legendComponent = (
        <SurveyLegendRange
          from={1}
          to={10}
          legends={[
            t('corporatePsychoDiagLegendStronglyDisagree'),
            t('corporatePsychoDiagLegendSomewhatAgree'),
            t('corporatePsychoDiagLegendStronglyAgree'),
          ]}
        />
      )
      break

    case 'burnout':
      legendComponent = (
        <SurveyLegendRange
          from={0}
          to={6}
          legends={[
            t('corporatePsychoDiagLegendNever'),
            t('corporatePsychoDiagLegendOnceAYear'),
            t('corporatePsychoDiagLegendOnceAMonth'),
            t('corporatePsychoDiagLegendMoreThanOnceAMonth'),
            t('corporatePsychoDiagLegendOnceAWeek'),
            t('corporatePsychoDiagLegendMoreThanOnceAWeek'),
            t('corporatePsychoDiagLegendEveryday'),
          ]}
        />
      )
      break

    case 'motivation':
      legendComponent = (
        <SurveyLegendRange
          from={1}
          to={7}
          legends={[
            t('corporatePsychoDiagLegendFalse'),
            t('corporatePsychoDiagLegendMostlyFalse'),
            t('corporatePsychoDiagLegendSomewhatFalse'),
            t('corporatePsychoDiagLegendTrueAndFalse'),
            t('corporatePsychoDiagLegendSomewhatTrue'),
            t('corporatePsychoDiagLegendMostlyTrue'),
            t('corporatePsychoDiagLegendTrue'),
          ]}
        />
      )
      break

    case 'satisfaction':
      legendComponent = (
        <SurveyLegendRange
          from={1}
          to={6}
          legends={[
            t('corporatePsychoDiagLegendStronglyDisagree'),
            t('corporatePsychoDiagLegendSomewhatAgree'),
            t('corporatePsychoDiagLegendStronglyAgree'),
          ]}
        />
      )
      break

    case 'commitment':
      legendComponent = (
        <SurveyLegendRange
          from={1}
          to={7}
          legends={[
            t('corporatePsychoDiagLegendStronglyDisagree'),
            t('corporatePsychoDiagLegendIndifferent'),
            t('corporatePsychoDiagLegendStronglyAgree'),
          ]}
        />
      )
      break

    case 'who-5':
      legendComponent = (
        <SurveyLegendRange
          from={0}
          to={5}
          legends={[
            t('wellnessSuiteLegendNever'),
            t('wellnessSuiteLegendSometimes'),
            t('wellnessSuiteLegendLessThanHalfOfMyTime'),
            t('wellnessSuiteLegendNeverMoreThanHalfOfMyTime'),
            t('wellnessSuiteLegendNeverMostOfMyTime'),
            t('wellnessSuiteLegendNeverAlways'),
          ]}
        />
      )
      break

    case 'phq-9':
      legendComponent = (
        <SurveyLegendRange
          from={0}
          to={3}
          legends={[
            t('wellnessSuiteLegendPhq0'),
            t('wellnessSuiteLegendPhq1'),
            t('wellnessSuiteLegendPhq2'),
            t('wellnessSuiteLegendPhq3'),
          ]}
        />
      )
      break

    case 'gad-7':
      legendComponent = (
        <SurveyLegendRange
          from={0}
          to={3}
          legends={[
            t('wellnessSuiteLegendGad0'),
            t('wellnessSuiteLegendGad1'),
            t('wellnessSuiteLegendGad2'),
            t('wellnessSuiteLegendGad3'),
          ]}
        />
      )
      break

    default:
      return `Survey legend not implemented for type '${survey.legendType}'`
  }

  return (
    <>
      <Grid container className={classes.surveyLegend}>
        <Grid item xs={12} class={classes.descriptionContainer}>
          <Paper elevation={3} className={classes.descriptionPaper}>
            <Typography className={classes.descriptionText}>
              {survey.description}
            </Typography>

            <Grid container>
              <Grid item xs={12}>
                {legendComponent}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

SurveyLegend.displayName = 'SurveyLegend'

export default SurveyLegend
export { SurveyLegend }
