import React from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'
import { Heading } from '@/components/Text'
import { WhiteButton } from '@/components'
import { navigate } from '@reach/router'
import { NavigateBack } from '@/components/Nav/NavigateBack'

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    paddingBottom: '2rem',
  },

  columnsContainer: {
    margin: '1rem 0',
  },

  columnHeader: {
    color: '#1e2864',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'left',
  },

  courseBox: {
    background:
      'linear-gradient(90deg, rgb(235, 46, 103) 0%, rgb(229, 62, 116) 100%) rgb(239, 30, 91)',
    padding: '0.5rem',
    borderRadius: '1rem',
  },

  courseHeader: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: '#fff',
  },

  courseIntro: {
    color: '#fff',
    fontSize: '0.8rem',
    marginTop: '1rem',
  },

  detailBox: {
    marginTop: '0.5rem',
    padding: '0 3rem',

    [theme.breakpoints.down('md')]: {
      padding: '0 1rem',
    },
  },

  detailLead: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textAlign: 'left',
  },

  detailText: {
    fontSize: '0.8rem',
    textAlign: 'justify',
  },

  groupTherapyContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },

  highlightBox: {
    padding: '0.5rem',
  },

  highlightDetails: {
    fontSize: '0.8rem',
  },

  highlightHeader: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#1e2864',
  },

  highlightsContainer: {
    margin: '1rem 0',
  },

  introSubheadingText: {
    marginTop: '1rem',
    textAlign: 'justify',
    fontSize: '1rem',
  },

  signUpButton: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },

  signupContainer: {
    marginTop: '0.5rem',
    justifyContent: 'center',
  },

  startingSoonHeader: {
    marginTop: '2rem',
    fontSize: '2rem',
    color: '#f01e5a',
    fontWeight: 'bold',
  },
}))

const GroupTherapySignup = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('groupTherapySignup')
  const { settingsByKey } = useStoreState((state) => state.settings)

  const navigateToMindfulnessSignup = () => {
    if (settingsByKey) {
      navigate(settingsByKey['mindfulness-stress-management-group-therapy'])
    }
  }

  return (
    <>
      <Grid container xs={12} className={classes.groupTherapyContainer}>
        <NavigateBack />
        <Heading>{t('groupTherapyHeader')}</Heading>

        <Container component="main" className={classes.bodyContainer}>
          <span
            className={classes.introSubheadingText}
            dangerouslySetInnerHTML={{
              __html: t('groupTherapyIntro'),
            }}
          ></span>

          <Grid container xs={12} className={classes.highlightsContainer}>
            <Grid item md={4} xs={12} className={classes.highlightBox}>
              <Typography className={classes.highlightHeader}>
                {t('frequencyHighlightHeader')}
              </Typography>
              <Typography className={classes.highlightDetails}>
                {t('frequencyHighlightDetails')}
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} className={classes.highlightBox}>
              <Typography className={classes.highlightHeader}>
                {t('formatHighlightHeader')}
              </Typography>
              <Typography className={classes.highlightDetails}>
                {t('formatHighlightDetails')}
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} className={classes.highlightBox}>
              <Typography className={classes.highlightHeader}>
                {t('lengthHighlightHeader')}
              </Typography>
              <Typography className={classes.highlightDetails}>
                {t('lengthHighlightDetails')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container xs={12} classes={classes.columnsContainer}>
            <Grid container md={6} xs={12}>
              <Grid item xs={12} className={classes.detailBox}>
                <Typography className={classes.columnHeader}>
                  {t('columnHeader1')}
                </Typography>
                <Typography className={classes.detailLead}>
                  {t('lead1')}
                </Typography>
                <Typography className={classes.detailText}>
                  {t('text1')}
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.detailBox}>
                <Typography className={classes.detailLead}>
                  {t('lead2')}
                </Typography>
                <Typography className={classes.detailText}>
                  {t('text2')}
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.detailBox}>
                <Typography className={classes.detailLead}>
                  {t('lead3')}
                </Typography>
                <Typography className={classes.detailText}>
                  {t('text3')}
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.detailBox}>
                <Typography className={classes.detailLead}>
                  {t('lead4')}
                </Typography>
                <Typography className={classes.detailText}>
                  {t('text4')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container md={6} xs={12}>
              <Grid item xs={12} className={classes.detailBox}>
                <Typography className={classes.columnHeader}>
                  {t('columnHeader2')}
                </Typography>
                <Typography className={classes.detailLead}>
                  {t('lead5')}
                </Typography>
                <Typography className={classes.detailText}>
                  {t('text5')}
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.detailBox}>
                <Typography className={classes.detailLead}>
                  {t('lead6')}
                </Typography>
                <Typography className={classes.detailText}>
                  {t('text6')}
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.detailBox}>
                <Typography className={classes.detailLead}>
                  {t('lead7')}
                </Typography>
                <Typography className={classes.detailText}>
                  {t('text7')}
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.detailBox}>
                <Typography className={classes.detailLead}>
                  {t('lead8')}
                </Typography>
                <Typography className={classes.detailText}>
                  {t('text8')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Typography className={classes.startingSoonHeader}>
            {t('groupsStartingSoon')}
          </Typography>

          <Grid container xs={12} className={classes.signupContainer}>
            <Grid item md={6} xs={12} className={classes.courseBox}>
              <Typography className={classes.courseHeader}>
                {t('mindfulnessGroupHeader')}
              </Typography>
              <Typography className={classes.courseIntro}>
                {t('mindfulnessGroupIntro')}
              </Typography>
              <WhiteButton
                className={classes.signUpButton}
                onClick={navigateToMindfulnessSignup}
              >
                {t('mindfulnessSignupButtonText')}
              </WhiteButton>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}

GroupTherapySignup.displayName = 'GroupTherapySignup'
export { GroupTherapySignup }
export default GroupTherapySignup
