import { LANGUAGES } from 'constants'

const resolveLanguageFromHostname = () => {
  const hostname = window.location.hostname.toLowerCase()
  let language = LANGUAGES.DEFAULT

  if (hostname === 'localhost') {
    return localStorage.getItem('preferredLanguage') || 'hu'
  }

  if (hostname) {
    if (hostname.indexOf('uatester') > -1 || hostname.indexOf('ua') > -1) {
      language = LANGUAGES.UKRANIAN
    } else if (
      hostname.indexOf('tester') > -1 ||
      hostname.indexOf('clinic') > -1
    ) {
      language = LANGUAGES.ENGLISH
    }
  }

  return language
}

export { resolveLanguageFromHostname }
export default resolveLanguageFromHostname
