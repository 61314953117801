import { useEffect } from 'react'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import store from '../../store'
import { PushTypes } from '../../constants/pushTypes'
import { navigate } from '@reach/router'
import { useAbly } from '../../hooks/useAbly'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'

interface ProviderFoundMessage {
  name: PushTypes.PROVIDER_FOUND
  data: {
    requestId: string
  }
}

interface IncomingCallMessage {
  name: PushTypes.INCOMING_CALL
  data: { twilioRoomUniqueName: string; twilioToken: string }
}

interface ManualEndCallMessage {
  name: PushTypes.MANUAL_END_CALL
  data: { requestId: Number; action: string; executor: string }
}

type AblyMessage = ProviderFoundMessage | IncomingCallMessage
export const PatientAblyHandler = () => {
  const { profileContents } = useStoreState((state) => state.profile)
  const {
    i18n: { language: lng },
  } = useTranslation()

  const { fetchIncomingRequest } = useStoreActions(
    (actions) => actions.incomingRequest
  )

  const {
    incomingRequest: { setTwilioCredentials },
  } = useStoreActions((actions) => actions)

  function handleProviderFound(message: ProviderFoundMessage) {
    fetchIncomingRequest({
      requestId: Number(message.data.requestId),
      language: lng,
    })
    mixpanel.track('Ably message', message)
    navigate('/patient/match')
  }

  function handleIncomingCall(message: IncomingCallMessage) {
    mixpanel.track('Ably message', message)
    setTwilioCredentials(message.data)
    navigate('/patient/call-ring')
  }

  const handleManualEndCall = (
    message: ManualEndCallMessage,
    currentRequestId: Number
  ) => {
    mixpanel.track('Ably message', message)

    if (message.data.requestId === currentRequestId) {
      console.log('Manually force-closing current request #', currentRequestId)
      mixpanel.track('Manually ending a call')
      navigate('/patient/rematch')
    } else {
      console.warn(
        `We're trying to end request #${message.data.requestId} that is not current #${currentRequestId}, ignoring force-close message`
      )
    }
  }

  const ably = useAbly()
  useEffect(() => {
    if (!profileContents.id) return
    if (!ably) return
    const channel = ably.channels.get(`user:${profileContents?.id}`)

    channel.subscribe(function (message) {
      if (message.name === PushTypes.PROVIDER_FOUND) {
        handleProviderFound(message as ProviderFoundMessage)
      } else if (message.name === PushTypes.INCOMING_CALL) {
        handleIncomingCall(message as IncomingCallMessage)
      } else if (message.name === PushTypes.MANUAL_END_CALL) {
        const currentRequestId = store?.getState()?.request?.requestId

        handleManualEndCall(
          message as ManualEndCallMessage,
          Number(currentRequestId)
        )
      }

      console.log('[PatientAblyHandler] Ably message', message)
    })
    return () => channel.unsubscribe()
  }, [profileContents.id, ably])

  return null
}
