import { action, thunk } from 'easy-peasy'
import { WebinarsModel } from './webinar.interface'
import * as webinarsService from '../../services/webinars.service'

const webinarsModel: WebinarsModel = {
  webinars: [],

  fetchWebinars: thunk(async (actions, payload) => {
    const webinars = await webinarsService.fetchWebinars({
      language: payload.language,
    })

    actions.setWebinars(webinars)
  }),

  setWebinars: action((state, payload) => {
    state.webinars = payload
  }),
}

export { webinarsModel }
