import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { useTranslation } from 'react-i18next'
import Avatar from '@material-ui/core/Avatar'
import Drawer from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { authService } from '../../services/authService'
import { sideMenuStyles } from './styles'
import { validateLocales } from '../../helpers/languageFormatter'
import { EXTERNAL_FILES } from '../../constants'

const { logout } = authService

const profMenuItems = [
  { link: '/professional/dashboard', i18nId: 'homePage', external: false },
  {
    link: '/professional-meetings',
    i18nId: 'professionalMeetings',
    external: false,
  },
]

const patientMenuItems = [
  { link: '/', i18nId: 'homePage', external: false },
  { link: '/patient/consultancies', i18nId: 'consultations', external: false },
  { link: '/patient/profile', i18nId: 'profile', external: false },
  {
    link: 'https://blog.dokiapp.hu/',
    i18nId: 'blog',
    external: true,
    languages: ['hu'],
  },
  {
    link: '/patient/knowledge-base',
    i18nId: 'knowledgeBase',
    languages: ['hu'],
    external: false,
  },
  {
    link: '/patient/surveys/risk-analysis',
    i18nId: 'riskAnalysisSurvey',
    external: false,
  },
]

const SideMenu = () => {
  const classes = sideMenuStyles()
  const { i18n, t } = useTranslation('sideMenu')

  const language = validateLocales(i18n.language)
  const linkedFiles = (EXTERNAL_FILES as any)[language]

  const {
    layout: { isSideMenuOpen, isMobile },
    profile: { profileContents },
  } = useStoreState((state) => state)
  const { setIsSideMenuOpen } = useStoreActions((actions) => actions.layout)
  const { account, subscriptionRetrieved } = useStoreState(
    (state) => state.subscription
  )
  const { fetchSubscription } = useStoreActions((state) => state.subscription)
  const [isBmw, setIsBmw] = useState(false)

  useEffect(() => {
    fetchSubscription()
  }, [])

  useEffect(() => {
    if (subscriptionRetrieved === true) {
      if (account && account.key === 'bmw-mental-health') {
        setIsBmw(true)
      }
    }
  }, [subscriptionRetrieved, account])

  let menuItems =
    profileContents.userTypeId === 1
      ? profMenuItems
      : patientMenuItems.filter((pmi) => {
          // If no language is enforced, include the menu item
          if (!pmi.languages) return true

          return pmi.languages.some(
            (expectedLanguage) => expectedLanguage === language
          )
        })

  if (isBmw === true) {
    menuItems = menuItems.filter((menuItem) => {
      if (
        menuItem.link === '/patient/consultancies' ||
        menuItem.link === 'https://blog.dokiapp.hu/' ||
        menuItem.link === '/patient/knowledge-base' ||
        menuItem.link === '/patient/surveys/risk-analysis'
      ) {
        return false
      }

      return true
    })
  }

  console.log('menu items to show', menuItems, isBmw)

  const closeSidebarMenuClasses = isMobile
    ? [classes.closeSideMenu, classes.closeSideMenuMobilePadding].join(' ')
    : classes.closeSideMenu

  return (
    <Drawer
      anchor={'right'}
      open={isSideMenuOpen}
      PaperProps={{ className: classes.sideMenuStyles }}
      classes={{ modal: classes.modal }}
      onClose={() => setIsSideMenuOpen(false)}
    >
      <div className={classes.sideMenuContainer}>
        <div className={classes.sideMenuUpper}>
          <div className={closeSidebarMenuClasses}>
            <IconButton
              aria-label="open-menu"
              onClick={() => setIsSideMenuOpen(false)}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <div className={classes.avatarContainer}>
            <Avatar
              alt="username"
              src={profileContents?.avatarUrl}
              className={classes.avatar}
            />
            <Typography className={classes.userName} component="h3">
              {profileContents.userTypeId === 1 && (
                <strong>{`${profileContents.namePrefix || ''} ${
                  profileContents.lastName
                } ${profileContents.firstName}`}</strong>
              )}
              {profileContents.userTypeId === 2 && (
                <strong>{`${profileContents.name}`}</strong>
              )}
            </Typography>
            <Typography className={classes.userEmail} component="h4">
              {profileContents?.email}
            </Typography>
          </div>
        </div>
        <div className={classes.sideMenu}>
          <div>
            <div className={classes.links}>
              {menuItems.map((menuItem, i) => {
                if (menuItem.external) {
                  return (
                    <a
                      key={i}
                      className={classes.mainLink}
                      href={menuItem.link}
                      onClick={() => setIsSideMenuOpen(false)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t<string>(menuItem.i18nId)}
                    </a>
                  )
                }
                return (
                  <Link
                    key={i}
                    to={menuItem.link}
                    className={classes.mainLink}
                    onClick={() => setIsSideMenuOpen(false)}
                  >
                    {t<string>(menuItem.i18nId)}
                  </Link>
                )
              })}
            </div>
          </div>
          <div className={classes.links}>
            <a
              href={linkedFiles.TERMS_AND_CONDITIONS}
              className={classes.secondaryLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t<string>('termsAndConditions')}
            </a>
            <a
              href={linkedFiles.APP_PRIVACY_POLICY}
              className={classes.secondaryLink}
              target="_blank"
              rel="noreferrer noopener"
              style={{ lineHeight: '20px', marginBottom: 10 }}
            >
              {t<string>('privacyPolicy')}
            </a>
            <a
              href={linkedFiles.PRIVACY_POLICY}
              className={classes.secondaryLink}
              target="_blank"
              rel="noreferrer noopener"
              style={{ lineHeight: '20px' }}
            >
              {t<string>('privacyNotice')}
            </a>

            {!isBmw && (
              <Link
                to="/what-is-dokiapp"
                className={classes.secondaryLink}
                onClick={() => setIsSideMenuOpen(false)}
              >
                {t<string>('whatIsDokiApp')}
              </Link>
            )}

            <Link
              to="/patient/contact"
              className={classes.secondaryLink}
              onClick={() => setIsSideMenuOpen(false)}
            >
              {t<string>('contact')}
            </Link>
            <span className={classes.secondaryLink} onClick={() => logout()}>
              {t<string>('logout')}
            </span>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export { SideMenu }
