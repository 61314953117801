import React, { Fragment, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { Heading } from '../../../components/Text/Heading'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from '@/store/store.hooks'

const useStyles = makeStyles((theme) => ({
  dashBoardContainer: {
    padding: '60px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
}))

const Contact = () => {
  const classes = useStyles()
  const { t } = useTranslation('contact')
  const { account, subscriptionRetrieved } = useStoreState(
    (state) => state.subscription
  )
  const { fetchSubscription } = useStoreActions((state) => state.subscription)
  const [isBmw, setIsBmw] = useState(false)

  useEffect(() => {
    fetchSubscription()
  }, [])

  useEffect(() => {
    if (subscriptionRetrieved === true) {
      if (account && account.key === 'bmw-mental-health') {
        setIsBmw(true)
      }
    }
  }, [subscriptionRetrieved, account])

  return (
    <Fragment>
      <Grid item xs={12} className={classes.dashBoardContainer}>
        <Heading>{t<string>('heading')}</Heading>

        <p>{t<string>('paragraph')}</p>

        {!isBmw && (
          <p>
            {t<string>('corporateSubscriptionInfoHeader')}
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: t<string>('corporateSubscriptionInfoLine1'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t<string>('corporateSubscriptionInfoLine2'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t<string>('corporateSubscriptionInfoLine3'),
                }}
              />
            </ul>
          </p>
        )}

        <p>
          <strong>{t<string>('general')}</strong> <br />
          hello@dokiapp.hu
        </p>
        <p>
          <strong>{t<string>('technical')}</strong>
          <br />
          support@dokiapp.hu
        </p>

        <p>{t<string>('text')}</p>

        <p>
          DokiLab Kft. <br />
          4400 Nyíregyháza Kalevala sétány 51. <br />
          {t<string>('taxNum')} 27063804-2-15 <br />
          {t<string>('companyRegNum')} 15-09-086670
        </p>
      </Grid>
    </Fragment>
  )
}

export { Contact }
