import React from 'react'
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { GradientButton } from '@/components'
import { navigate } from '@reach/router'
import EventIcon from '@material-ui/icons/Event'
import RoomIcon from '@material-ui/icons/Room'

const useStyles = makeStyles((theme) => ({
  eventTimeContainer: {
    margin: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',

    '& > p': {
      color: '#454d7f',
      fontWeight: 'bold',
    },
  },

  webinarButton: {
    marginTop: '1rem',
  },

  webinarDescription: {
    marginTop: '1rem',
    textAlign: 'justify',
    fontSize: '0.8rem',
  },

  webinarHeader: {
    fontWeight: 600,
    fontSize: '1.5rem',
    color: '#f01e5a',
    width: '100%',
  },

  webinarHeadline: {
    marginTop: '1rem',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#454d7f',
    textAlign: 'center',
    width: '100%',
  },

  webinarPaper: {
    padding: '2rem',
    margin: '2rem',
    boxShadow:
      '0 4px 10px rgba(240, 30, 90, 0.6), 0 2px 5px rgba(240, 30, 90, 0.3)',

    [theme.breakpoints.down('md')]: {
      padding: '0.5rem',
      margin: '0 0 1rem 0',
    },
  },

  webinarSubheader: {
    marginTop: '1rem',
    fontWeight: 600,
    fontSize: '1rem',
    fontStyle: 'italic',
    color: '#454d7f',
    textAlign: 'center',
    width: '100%',
  },

  webinarVenueIcon: {
    marginLeft: '2rem',
  },
}))

const Webinar = ({ webinar }) => {
  const classes = useStyles()
  const { t } = useTranslation('webinars')

  const handleRegistration = () => {
    window.open(webinar.registrationLink, '_blank')
  }

  const parseDateString = (dateStr) => {
    const [datePart, timePart] = dateStr.split('T') // Split date and time
    const [year, month, day] = datePart.split('-').map(Number)
    const [hour, minute] = timePart.split(':').map(Number)

    return { year, month, day, hour, minute }
  }

  const formatDateTime = () => {
    const startDate = parseDateString(webinar.startDateTime)
    const endDate = parseDateString(webinar.endDateTime)

    const formatted = `${startDate.year}. ${new Intl.DateTimeFormat('hu-HU', {
      month: 'long',
    }).format(new Date(startDate.year, startDate.month - 1))} ${
      startDate.day
    }. ${startDate.hour.toString().padStart(2, '0')}:${startDate.minute
      .toString()
      .padStart(2, '0')} - ${endDate.hour
      .toString()
      .padStart(2, '0')}:${endDate.minute.toString().padStart(2, '0')}`

    return formatted
  }

  return (
    <>
      <Paper elevation={3} className={classes.webinarPaper}>
        <Grid container xs={12}>
          <Typography
            component="div"
            className={classes.webinarHeader}
            dangerouslySetInnerHTML={{
              __html: webinar.header,
            }}
          ></Typography>

          <Typography
            component="div"
            className={classes.webinarSubheader}
            dangerouslySetInnerHTML={{
              __html: webinar.subheader,
            }}
          ></Typography>

          <Typography
            component="div"
            className={classes.webinarDescription}
            dangerouslySetInnerHTML={{
              __html: webinar.description,
            }}
          ></Typography>

          <Box className={classes.eventTimeContainer}>
            <EventIcon style={{ color: '#454d7f' }} />
            <Typography>{formatDateTime()}</Typography>
            <RoomIcon
              className={classes.webinarVenueIcon}
              style={{ color: '#454d7f' }}
            />
            <Typography>{webinar.venue}</Typography>
          </Box>

          <Typography
            component="div"
            className={classes.webinarHeadline}
            dangerouslySetInnerHTML={{
              __html: webinar.headline,
            }}
          ></Typography>

          <GradientButton
            onClick={handleRegistration}
            className={classes.webinarButton}
          >
            {t('registerButtonText')}
          </GradientButton>
        </Grid>
      </Paper>
    </>
  )
}

Webinar.displayName = 'Webinar'
export { Webinar }
export default Webinar
