/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Router } from '@reach/router'

import { Survey } from './Survey'
import { SurveyEvaluation } from './SurveyEvaluation'
import { SurveyList } from './SurveyList'
import { SurveyQuestions } from './SurveyQuestions'
import { CorporatePsychologicalDiagnostics } from './CorporatePsychologicalDiagnostics'
import { CorporatePsychologicalDiagnosticsEvaluation } from './CorporatePsychologicalDiagnosticsEvaluation'
import { WellnessSuite } from './WellnessSuite'
import { WellnessSuiteEvaluation } from './WellnessSuiteEvaluation'

const Surveys = ({}) => {
  return (
    <Router>
      <CorporatePsychologicalDiagnostics path="/corporate-diagnostics" />
      <CorporatePsychologicalDiagnosticsEvaluation path="/corporate-diagnostics/evaluation" />

      <WellnessSuite path="/wellness-suite" />
      <WellnessSuiteEvaluation path="/wellness-suite/evaluation" />

      <Survey path=":surveyId" />
      <SurveyQuestions path=":surveyId/questions" />
      <SurveyEvaluation path=":surveyId/evaluation" />
      <SurveyList path="/"></SurveyList>
    </Router>
  )
}

Surveys.displayName = 'Surveys'

Surveys.propTypes = {}

Surveys.defaultProps = {}

export default Surveys
export { Surveys }
