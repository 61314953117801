import { action, thunk } from 'easy-peasy'
import { IncomingRequestModel, Call } from './incomingRequest.interface'
import { fetchIncomingRequest } from '../../services/request.service'
import { get } from '../../services/request'

export const incomingRequestModel: IncomingRequestModel = {
  incomingRequestContents: undefined,
  twilioRoomUniqueName: undefined,
  twilioToken: '',
  call: undefined,
  // Flag to prevent race condition for patient search
  matched: false,
  setIncomingRequest: action((state, payload) => {
    state.incomingRequestContents = payload
  }),
  fetchIncomingRequest: thunk(async (actions, payload) => {
    actions.setMatched(true)
    const profileContents = await fetchIncomingRequest({
      requestId: payload.requestId,
      language: payload.language,
    })
    actions.setIncomingRequest(profileContents)
    actions.setMatched(false)
  }),
  setTwilioCredentials: action((state, payload) => {
    state.twilioRoomUniqueName = payload.twilioRoomUniqueName
    state.twilioToken = payload.twilioToken
  }),
  setCall: action((state, payload) => {
    state.call = payload
  }),
  fetchCall: thunk(async (actions, payload) => {
    const call = await get<Call>(
      `/call/callByRequestId/${payload.requestId}?lng=${payload.language}`
    )
    actions.setCall(call)
  }),
  setMatched: action((state, payload) => {
    state.matched = payload
  }),
}
